import { computed, ref, watch, onMounted } from "vue"
import store from "@/store"
import { GETTERS, COMMITS } from "@/store/constants"
import uiEvents from "@/configs/uiEvents"
import { eventBus } from "./eventBus.plugin"
import { agentService } from "@/services"
import { hsAgiHttp, parseError } from "@/utils"
import router from "@/router"
import routerNames from "@/configs/routerNames"
import dayjs from "dayjs"

const SELECTED_THREAD_KEY = "helloscribe.locals.user.selected_thread_id"
const UPLOADED_FILE_LIST_KEY =
  "helloscrhelloscribe.locals.user.uploaded_files_list"

const UPDATE_TYPES = {
  NEW_THREAD: "new_thread",
  CONTINUE_THREAD: "continue_thread",
  NEW_QUESTION: "new_question",
  POST_ANSWER: "post_answer",
  SKIP_CLARIFICATION: "skip_clarification",
  NEW_TASK_LIST: "new_task_list",
  NEW_SUMMARY: "new_summary",
  TASK_APPROVAL: "task_approval",
  TASK_COMPLETION: "task_completion",
  TASK_REDO: "task_redo",
  THREAD_COMPLETED: "thread_completed",
  TASK_UPDATE: "task_update",
  THREAD_UPDATE: "thread_update",
  ERROR: "error",
}

const AGI_STEP = {
  CLARIFICATION: 1,
  CREATE_TASK_LIST: 2,
  SUMMARIZATION: 3,
  APPROVAL: 4,
  TASK_EXECUTION: 5,
}

const user = computed(() => store.getters[GETTERS.USER])
const subscriptoin = computed(() => store.getters[GETTERS.SUBSCRIPTION])
const tokenInfo = computed(() => store.getters[GETTERS.TOKEN_INFO])
/** @type {import("vue").Ref<AgiThread[]>} */
const threadList = computed(() => store.getters[GETTERS.AGENT_CHAT_HISTORY])
const selectedProject = computed(() => store.getters[GETTERS.SELECTED_PROJECT])
/** @type {import("vue").Ref<AgiThread|null>} */
const selectedThread = ref(null)
const agiBusy = ref(false)
const userInput = ref("")
const allowAutoScrolling = ref(true)
const language = ref({
  input: "EN-US",
  output: "EN-US",
})
const uploadedFiles = ref([])
const uploadingFiles = ref(false)
/** @type {AbortController} */
let abortController = new AbortController()

watch(selectedThread, (t) => {
  if (!t) {
    localStorage.removeItem(SELECTED_THREAD_KEY)
    return
  }
  localStorage.setItem(SELECTED_THREAD_KEY, t.id)
})

watch(uploadedFiles, (files) => {
  localStorage.setItem(UPLOADED_FILE_LIST_KEY, JSON.stringify(files))
})

onMounted(() => {
  const cachedList = localStorage.getItem(UPLOADED_FILE_LIST_KEY)
  if (!cachedList) {
    uploadedFiles.value = []
    return
  }
  try {
    const list = JSON.parse(cachedList)
    uploadedFiles.value = list.filter(
      (item) =>
        dayjs(item.exp).isValid() &&
        dayjs(item.exp).isAfter(Date.now(), "minute"),
    )
  } catch (err) {
    uploadedFiles.value = []
  }
})

async function approveTaskList(approve = false) {
  agiBusy.value = true
  selectedThread.value.loading_summarization = true

  if (!approve) {
    selectedThread.value.tasks = []
    selectedThread.value.tasks_summary = ""
    await agiGenerate({ approve })
    return
  }
  await agiGenerate({ approve })
}

async function startIncompleteThread(id, thread = null) {
  const threads = store.getters[GETTERS.AGENT_CHAT_HISTORY]
  const t = thread || threads.find((t) => t.id === id)
  if (!t) {
    window.devErr("Selected thread not found")
    eventBus.emit(uiEvents.GLOBAL.SHOW_ALERT_MODAL, {
      severity: "error",
      title: "Unable to continue the thread.",
      body: "Try again",
    })
    return
  }

  try {
    selectedThread.value = await agentService.getThreadById(t.id || t._id)
  } catch (err) {
    window.devErr(err?.response?.data || err)
    eventBus.emit(uiEvents.GLOBAL.SHOW_ALERT_MODAL, {
      severity: "error",
      title: "Selected thread not found",
      body: parseError(err),
    })
    selectedThread.value = null
    return
  }

  if (selectedThread.value.step === AGI_STEP.CLARIFICATION) {
    return
  }
  if (
    selectedThread.value.step === AGI_STEP.APPROVAL &&
    !selectedThread.value.tasks_confirmed
  ) {
    return
  }
  await agiGenerate({})
}

async function sendMsg() {
  if (tokenInfo.value.balance < 1) {
    if (user.value.invited) {
      eventBus.emit(uiEvents.GLOBAL.SHOW_ALERT_MODAL, {
        severity: "error",
        title: "Not enough tokens to run AutoPilot",
        body: "Please contact your admin to get more tokens.",
      })
    } else if (subscriptoin.value?.planName.toLowerCase() === "free trial") {
      eventBus.emit(uiEvents.GLOBAL.SHOW_ALERT_MODAL, {
        severity: "error",
        title: "Not enough tokens to run AutoPilot",
        body: "Please upgrade your subscription to keep using HelloScribe.",
        callback: async () => {
          await eventBus.emit(uiEvents.GLOBAL.SHOW_TOKEN_RAN_OUT_MODAL, true)
        },
      })
    } else {
      eventBus.emit(uiEvents.GLOBAL.SHOW_ALERT_MODAL, {
        severity: "error",
        title: "Seems like you ran out of tokens!",
        body: "Purchase more tokens to keep using HelloScribe without disruption.",
        callback: async () => {
          router.push({ name: routerNames.BILLING })
        },
      })
    }
    return
  }

  if (selectedThread.value && selectedThread.value.completed) {
    selectedThread.value = null
  }

  agiBusy.value = true
  const msg = userInput.value.trimEnd()
  userInput.value = ""
  if (!msg || msg.length < 2) {
    eventBus.emit(uiEvents.GLOBAL.SHOW_ALERT_MODAL, {
      severity: "error",
      title: "Please enter a text to send!",
      body: "Message length should be 10 or more characters.",
    })
    agiBusy.value = false
    return
  }

  const payload = {
    language: "English",
    objective: msg,
    answer: msg,
  }

  if (selectedThread.value) {
    payload.question_idx = selectedThread.value.clarifications.length - 1
    selectedThread.value.clarifications[payload.question_idx].answer = msg
  } else {
    selectedThread.value = {
      objective: payload.objective,
      loading_clarification: true,
      clarifications: [],
      step: AGI_STEP.CLARIFICATION,
      tasks: [],
    }
  }

  try {
    await agiGenerate(payload)
  } catch (err) {
    eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
      severity: "error",
      text: parseError(err),
    })
    window.devErr(err.response?.data || err)
  }
}

/**
 * @param {{
 *   answer: string | undefined;
 *   approve: boolean;
 *   question_idx: number | undefined;
 *   objective: string | undefined;
 *   language: {input: string; output: string}}} data
 */
async function agiGenerate(data) {
  const user = store.getters[GETTERS.USER]
  if (!user || !user._id) {
    eventBus.emit(uiEvents.GLOBAL.SHOW_ALERT_MODAL, {
      severity: "error",
      title: "No logged in user found",
      body: "Please refresh the app or login again.",
    })
    return
  }

  agiBusy.value = true
  renderLoadingUi()

  const payload = {
    ...data,
    filenames: uploadedFiles.value.map((f) => f.filename),
    user_id: user._id,
  }
  if (selectedThread.value && selectedThread.value.id) {
    payload.thread_id = selectedThread.value.id
    payload.language = selectedThread.value.language
    if (!payload.update_type) {
      switch (selectedThread.value.step) {
        case AGI_STEP.CLARIFICATION:
          payload.update_type = UPDATE_TYPES.POST_ANSWER
          break
        case AGI_STEP.APPROVAL:
          payload.update_type = UPDATE_TYPES.TASK_APPROVAL
          if (data.approve) {
            selectedThread.value.step = AGI_STEP.TASK_EXECUTION
          }
          break
        default:
          payload.update_type = UPDATE_TYPES.CONTINUE_THREAD
          break
      }
    }
  } else {
    payload.thread_id = ""
    payload.update_type = UPDATE_TYPES.NEW_THREAD
    payload.language = JSON.parse(JSON.stringify(language.value))
    payload.project_id = selectedProject.value?._id || null
  }

  try {
    abortController = new AbortController()
    const res = await (
      await hsAgiHttp()
    ).post(
      "/agi/generate",
      { ...payload, token_info: tokenInfo.value },
      { signal: abortController.signal },
    )
    uploadedFiles.value = []
    const updatedTokenInfo = res.data.data.tokenInfo
    if (
      updatedTokenInfo &&
      Object.isObject(updatedTokenInfo) &&
      Object.hasOwn(updatedTokenInfo, "balance")
    ) {
      store.commit(COMMITS.SET_TOKEN_INFO, updatedTokenInfo)
    }
    const thread = res.data.data.thread
    if (!thread) {
      throw new Error(
        "Unable to continue. Please refresh the app to continue the thread",
      )
    }
    selectedThread.value = thread
    setTimeout(autoScroll, 500)

    if (
      selectedThread.value.step === AGI_STEP.CLARIFICATION ||
      selectedThread.value.step === AGI_STEP.APPROVAL ||
      selectedThread.value.completed
    ) {
      agiBusy.value = false

      if (selectedThread.value.completed) {
        const newList = threadList.value.filter(
          (t) => t.id !== selectedThread.value.id,
        )
        newList.unshift(selectedThread.value)
        store.commit(COMMITS.SET_AGENT_CHAT_HISTORY, newList)
      }

      return
    }

    await agiGenerate({})
  } catch (err) {
    setTimeout(autoScroll, 500)
    window.devErr(err?.response?.data || err)
    const msg = parseError(err)
    agiBusy.value = false
    if (payload.update_type === UPDATE_TYPES.NEW_THREAD) {
      selectedThread.value = null
    }
    if (err.name === "CanceledError") {
      eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
        text: "Thread canceled",
        severity: "error",
      })
    } else if (err.response?.data?.error === "504 Deadline Exceeded") {
      await agiGenerate(payload)
    } else if (err.response?.data?.error === "TOKEN_RAN_OUT") {
      eventBus.emit(uiEvents.GLOBAL.SHOW_ALERT_MODAL, {
        severity: "error",
        title: "Balance Low. Please top up.",
        body: msg,
        callback: async () => {
          await eventBus.emit(uiEvents.GLOBAL.SHOW_TOKEN_RAN_OUT_MODAL, true)
        },
      })
    } else {
      eventBus.emit(uiEvents.GLOBAL.SHOW_ALERT_MODAL, {
        severity: "error",
        title: "Error occurred please refresh the app.",
        body: msg,
      })
    }
  }
}

async function redoTask(thread, taskId) {
  eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
    severity: "success",
    text: "Requesting a task redo, please wait...",
  })

  agiBusy.value = true
  selectedThread.value = thread
  selectedThread.value.step = AGI_STEP.TASK_EXECUTION
  selectedThread.value.completed = false

  for (let i = 0; i < selectedThread.value.tasks.length; i++) {
    if (selectedThread.value.tasks[i].id === taskId) {
      selectedThread.value.tasks[i].result = ""
      selectedThread.value.tasks[i].status = "In Progress"
      break
    }
  }

  await agiGenerate({
    task_id: taskId,
    instruction: "",
    update_type: UPDATE_TYPES.TASK_REDO,
  })
}

const startNewTask = () => {
  selectedThread.value = null
  eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
    text: "Your chat has been moved to the completed chat histories list.",
  })
}

function autoScroll() {
  // if (!allowAutoScrolling.value) return
  window.scrollTo({
    top: window.innerHeight * 100,
    left: 0,
    behavior: "smooth",
  })
}

function renderLoadingUi() {
  if (!selectedThread.value) {
    return
  }
  if (selectedThread.value.step === AGI_STEP.CLARIFICATION) {
    selectedThread.value.loading_clarification = true
  } else if (selectedThread.value.step === AGI_STEP.CREATE_TASK_LIST) {
    selectedThread.value.loading_task_creation = true
  } else if (selectedThread.value.step === AGI_STEP.SUMMARIZATION) {
    selectedThread.value.loading_summarization = true
  }
}

function clearCanvas() {
  if (abortController) {
    abortController.abort("User stopped the AGI.")
  }
  selectedThread.value = null
  agiBusy.value = false
}

async function getLastSelectedThread() {
  const lastSelectedThreadId = localStorage.getItem(SELECTED_THREAD_KEY)
  if (!lastSelectedThreadId) return

  const maxRetries = 3
  let retries = 0

  while (retries < maxRetries) {
    try {
      const t = await agentService.getThreadById(lastSelectedThreadId)
      if (t && t.id) {
        if (t.completed) {
          localStorage.removeItem(SELECTED_THREAD_KEY)
        } else {
          selectedThread.value = t
          await startIncompleteThread(t.id, t)
        }
      }
      return // Success, exit the function
    } catch (err) {
      retries++
      console.error(`Attempt ${retries} failed:`, err)

      if (retries >= maxRetries || err.response) {
        // If we've reached max retries or received a response (even if it's an error), stop retrying
        window.devErr(`Failed to fetch thread after ${retries} attempts:`, err.response || err)
        localStorage.removeItem(SELECTED_THREAD_KEY)
        eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
          severity: "error",
          text: "Failed to load the last selected thread. Please try again later.",
        })
        return
      }

      // If it's a network error, wait before retrying
      if (err.code === 'ERR_NETWORK') {
        await new Promise(resolve => setTimeout(resolve, 2000 * retries)) // Exponential backoff
      }
    }
  }
}

async function skipClarificationStep() {
  const payload = {
    update_type: UPDATE_TYPES.SKIP_CLARIFICATION,
    user_id: user.value._id,
    thread_id: selectedThread.value.id,
    language: selectedThread.value.language,
    token_info: tokenInfo.value,
  }
  await agiGenerate(payload)
}

/**
 * @param {File[]} fileList
 */
async function uploadFiles(fileList) {
  if (fileList.length < 1) {
    eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
      text: "No files selected",
      severity: "error",
    })
    return
  }
  try {
    uploadingFiles.value = true
    const form = new FormData()
    fileList.forEach((f) => {
      form.append("files", f)
    })
    const res = await (
      await hsAgiHttp()
    ).post("/agi/generate/upload-file", form, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    if (
      res.data.data &&
      "length" in res.data.data &&
      res.data.data.length > 0
    ) {
      uploadedFiles.value.push(...res.data.data)
    }
    store.commit(COMMITS.SET_SHOW_QUICK_STARTS, false)
  } catch (err) {
    window.devErr(err)
    eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
      text: parseError(err),
      severity: "error",
    })
  } finally {
    uploadingFiles.value = false
  }
}

async function removeUploadedFile(file) {
  try {
    uploadingFiles.value = true
    const filename = encodeURIComponent(file.filename)
    await (await hsAgiHttp()).delete(`/agi/generate/upload-file/${filename}`)
    uploadedFiles.value = uploadedFiles.value.filter(
      (f) => f.filename !== file.filename,
    )
  } catch (err) {
    window.devErr(err)
    eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
      text: parseError(err),
      severity: "error",
    })
  } finally {
    uploadingFiles.value = false
  }
}

/************* the plugin **************/
export default {
  /**
   *  @param {import("vue").App} app
   */
  install(app) {
    const hsAgi = {
      threadList,
      selectedThread,
      agiBusy,
      userInput,
      allowAutoScrolling,
      language,
      uploadedFiles,
      uploadingFiles,

      UPDATE_TYPES,
      AGI_STEP,

      approveTaskList,
      redoTask,
      sendMsg,
      startIncompleteThread,
      startNewTask,
      clearCanvas,
      getLastSelectedThread,
      skipClarificationStep,
      uploadFiles,
      removeUploadedFile,
    }

    app.provide("hsAgi", hsAgi)
    app.config.globalProperties.$hsAgi = hsAgi
  },
}