import store from "@/store"
import { http } from "@/utils"
import { COMMITS, GETTERS } from "@/store/constants"
import { eventBus } from "@/plugins/eventBus.plugin"
import uiEvents from "@/configs/uiEvents"

/**
 * @param {string} planId
 * @returns {Promise<{user: User; subscription: Record<string, any>; redirectUrl: string}>}
 */
export async function createUserSubscription(planId) {
  const user = store.getters[GETTERS.USER]
  if (!user) return

  const res = await (await http()).post("/billing/subscription", { planId })
  console.log(res)
  return {
    user: res.data.user,
    subscription: res.data.subscription,
    redirectUrl: res.data.redirectUrl,
    clientSecret: res.data?.clientSecret,
  }
}
export async function confirmSubscription(clientSecret) {
  const user = store.getters[GETTERS.USER]
  if (!user) return

  const res = await (
    await http()
  ).post("/billing/confirm-payment-intent", { clientSecret })
  console.log(res)
  return {}
}

export async function upgradeSubscription(planId) {
  const user = store.getters[GETTERS.USER]
  if (!user) return

  // const res = "";
  const res = await (
    await http()
  ).post("/billing/subscription/upgrade", { planId })
  console.log(res)
  return {
    user: res.data.user,
    subscription: res.data.subscription,
    tokenInfo: res.data.tokenInfo,
    team: res.data.team,
    redirectUrl: res.data.redirectUrl,
    clientSecret: res.data.clientSecret,
  }
}

/**
 * Unsubscribe a user from their current subscription plan
 * @param {boolean} removeData - whether helloscribe should remove user's data or not.
 * @throws {Error} if there were any issues in the process.
 */
export async function unsubscribeUser() {
  const user = store.getters[GETTERS.USER]
  if (!user) throw new Error("User not found please login again")

  try {
    const res = await (await http()).delete("/billing/subscription")

    if (!res.data.success) {
      await eventBus.emit(uiEvents.GLOBAL.SHOW_ALERT_MODAL, {
        severity: "error",
        title: "Unable to unsubscribe at the moment.",
        body: res.data.message,
        callback: () => window.location.reload(),
      })
      return
    }

    await eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
      text: "Sucessfully unsubscribed from HelloScribe",
      severity: "success",
    })
  } catch (err) {
    window.devErr((err.response && err.response.data) || err)
    await eventBus.emit(uiEvents.GLOBAL.SHOW_ALERT_MODAL, {
      severity: "error",
      title: "Unable to usubscribe at the moment.",
      body: "The process was incomplete due to some internal errors. Please refresh the page and try again.",
      callback: () => window.location.reload(),
    })
  }
}

/**
 * Get the user's appsumo sub plan
 * @returns {Promise<AppSumoSubscription>}
 */
export const getAppSumoSub = async () => {
  const res = await (await http()).get("/subscription/appsumo")
  store.commit(COMMITS.SET_APPSUMO_SUBSCRIPTION, res.data.subscription)
  return res.data.subscription
}

/**
 * Get Config (PK KEY)
 * @returns {Promise<{publishableKey: string}>}
 */
export const getConfig = async () => {
  const res = await (await http()).get("/config")
  return res.data
}

// Converting the Stripe epoch timestamp to ISO 8601
export const secondsToISOString = (seconds) => {
  const date = new Date(seconds * 1000)
  return date.toISOString().substring(0, 10)
}

/**
 * Select an specific Togai plan for the user
 * @param {string} pricePlanId - id of the plan the user selected
 * @returns {Promise<Record<string, any>>}
 */
export const selectSubPlan = async (pricePlanId) => {
  const res = await (
    await http()
  ).post(`/subscription/select-plan`, {
    pricePlanId,
  })
  if (res.data.user) {
    store.commit(COMMITS.SET_USER, res.data.user)
  }
  if (res.data.account) {
    store.commit(COMMITS.SET_ACCOUNT, res.data.account)
  }
  return res.data
}

/**
 * get all the cards of an user.
 * @returns {Promise<any[]>}
 */
export async function getUserCards() {
  try {
    const res = await (await http()).get("/billing/cards")
    return res.data.cards || []
  } catch (err) {
    window.devErr(err)
    return []
  }
}

export const createAddCardLink = async (
  mode = "onboarding",
  pricePlanName,
  pricePlanId,
) => {
  if (!["sumoling", "additionalCard", "onboarding"].includes(mode)) {
    throw new Error("Invalid target")
  }

  const res = await (
    await http()
  ).post("/subscription/cards/new", { mode, pricePlanName, pricePlanId })
  return res.data.url
}

export async function getTokenInfo() {
  try {
    const { data } = await (await http()).get("/billing/tokens/details")
    return data.tokensInfo
  } catch (err) {
    window.devErr((err.response && err.response.data) || err)
    return {
      granted: 0,
      balance: 0,
    }
  }
}

/**
 * @param {number} amount - amount in dollars
 */
export async function purchaseTokens(amount) {
  const { data } = await (
    await http()
  ).post("/billing/tokens/purchase/instant", {
    amount,
  })
  // return data.tokenInfo
  return data
}

/**
 * @param {number} creditAmount
 * @returns {Promise<Record<string, any>> | null}
 */
export async function updatePayasyougo(creditAmount, enabled) {
  const { data } = await (
    await http()
  ).post("/billing/tokens/purchase/enable-payasyougo", {
    creditAmount,
    enabled,
  })
  return data.subscription
}

export async function resubscribe() {
  const { data } = await (
    await http()
  ).put("/billing/subscription", { action: "resubscribe" })
  return {
    user: data.user,
    subscription: data.subscription,
    team: data.team,
    cards: data.cards,
    tokenInfo: data.tokenInfo,
  }
}

export async function getDetails() {
  let data = {}
  try {
    const res = await (await http()).get("/billing/overview")
    data = res.data
  } catch (err) {
    await eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
      text: "Unable to get your info at the moment please try again.",
      severity: "error",
    })
  }
  return {
    subscription: data.subscription,
    team: data.team,
    tokenInfo: data.tokenInfo,
    user: data.user,
    cards: data.cards,
  }
}
